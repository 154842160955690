import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

const brands = {
	rozowa: "32",
	gdanski: "28",
	prasowy: "6",
	wege: "30",
	mleczarnia: "33",
	ja: "42",
	mily: "46",
};

export function NotFoundPage() {
	const { t } = useTranslation();
	const location = useLocation();

	if (
		location.pathname.split("/").some((u) => u === "menu" || u === "places")
	) {
		let redirect = "https://menu.mlecznebary.pl";

		const brand = location.pathname
			.split("/")
			.find((u): u is keyof typeof brands => Object.keys(brands).includes(u));
		if (brand) {
			redirect += `/${brands[brand]}`;
		}

		window.location.href = redirect;
		return null;
	}

	return (
		<div className="py-12 flex flex-col md:flex-row justify-center items-center gap-6">
			<p className="font-semibold text-xl">{t("notFound.text")}</p>
			<NavLink to="/" className="px-10 py-2 rounded-full border">
				{t("notFound.button")}
			</NavLink>
		</div>
	);
}
